import Splide from '@splidejs/splide';
import Video from '@splidejs/splide-extension-video';
import debounce = require('lodash.debounce');

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class Gallery {
    gallery: Splide;
    constructor(element: HTMLElement) {
        this.gallery = new Splide(element, {
            accessibility: true,
            drag: true,
            // @ts-ignore
            pagination: false,
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 0,
            arrows: true,
            autoWidth: true,
            keyboard: 'focused',
            padding: {
                left: '0rem',
                right: '0rem'
            },
            gap: '2rem',
            breakpoints: {
                600: {
                    gap: '1rem'
                }
            },
            video: {
                // setting to true is currently required due to a bug in the splide video extension creating
                // the embed url with an empty "playlist" parameter which break YouTube embed link
                loop: true
            }
        });
        this.gallery.on('mounted', () => {
            this.updateCaptions();
            window.addEventListener('resize', debounce(() => {
                this.updateCaptions();
            }, 50));
        })
        this.gallery.mount({ Video });

    }
    updateCaptions() {
        this.gallery.Components.Elements.slides.forEach(slide => {
            const slideImg = slide.querySelector('img') as HTMLImageElement;
            const slideCaption = slide.querySelector('[data-gallery-caption]') as HTMLElement;
            if (slideCaption) {
                if (slideImg.complete) {
                    const imageWidth = slideImg.getBoundingClientRect().width;
                    slideCaption.style.width = `${imageWidth}px`;
                    return false;
                }
                slideImg.onload = () => {
                    const imageWidth = slideImg.getBoundingClientRect().width;
                    slideCaption.style.width = `${imageWidth}px`;
                }
            }
        });
    }
}