
export class Alert {
    alertElement: HTMLElement;

    constructor(alertElement: HTMLElement, container?: HTMLElement) {

        this.alertElement = alertElement;

        if (this.alertElement.querySelector('[data-close]')) {
            this.alertElement.querySelector('[data-close]').addEventListener('click', () => {
                this.alertElement.setAttribute('aria-hidden', 'true');
            })
        }
    }
}