export class Search {
    searchElement: HTMLElement;
    searchInput: HTMLElement;
    triggerElement: HTMLElement;
    searchContainers: NodeList;

    constructor(searchElement: HTMLElement) {
        this.searchElement = searchElement;
        this.initElements();
        this.initListeners();
    }

    initElements = () => {
        this.triggerElement = this.searchElement.querySelector('[data-search-trigger]');
        this.searchContainers = this.searchElement.querySelectorAll('[data-search-container]');
        this.searchInput = this.searchElement.querySelector('input');
    }

    initListeners = () => {
        this.triggerElement.addEventListener('click', () => { this.togglePanelVisibility(this.searchContainers) });
    }

    togglePanelVisibility = (containers) => {
        containers.forEach((searchContainer: HTMLElement) => {
            if (searchContainer.getAttribute('aria-expanded') == 'true') {
                searchContainer.setAttribute('aria-expanded', 'false');
                this.searchElement.classList.remove('search-active')
            }
            else {
                searchContainer.setAttribute('aria-expanded', 'true');
                this.searchElement.classList.add('search-active')
                this.searchInput.focus();
            }
        })
    }
}

