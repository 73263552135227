import { SvgSprite } from '../modules/svg-loader';
import { nodeListToArray } from '../helpers/nodeListToArray';
import { mainNavigation } from '../modules/mainNavigation';
import { stickyHeader } from '../modules/sticky-navigation';
import { Search } from '../modules/search';
import { HeroVideo } from '../modules/heroVideo';
import { HomeCarousels } from '../modules/homeCarousel';
import { Alert } from '../modules/alert';
import { GlobalAlert } from '../modules/alert-global';
import { Accordion } from '../modules/accordion';
import { RevealPanel } from '../modules/revealPanel';
import { Gallery } from '../modules/gallery';
import { googleTranslateInit } from '../modules/google-translate';

const init = () => {
    new SvgSprite('/Static/img/adage-sprite.svg');
    navigationInit();
    stickyHeader();
    searchInit();
    heroInit();
    blocksInit();
    componentsInit();
    googleTranslateInit();
}

const navigationInit = () => {
    if (mainNavigation) mainNavigation.init();
}

const searchInit = () => {
    if (document.querySelector('[data-search]')) {
        new Search(document.querySelector('[data-search]'));
    }
}

const heroInit = () => {
    // Hero videos
    const heroVideoContainers = nodeListToArray(document.querySelectorAll('[data-hero-video-container]')) as HTMLElement[];
    heroVideoContainers.forEach(heroVideoContainer => {
        new HeroVideo(heroVideoContainer);
    });

    if (document.querySelector('[data-homecarousel-splide-container]')) {
        //Home Page Carousels
        new HomeCarousels(document.querySelector('[data-homecarousel-splide-container]'));
    }
}

const componentsInit = () => {
    const revealPanelEls = [].slice.call(document.querySelectorAll('[data-panel]')) as Array<HTMLElement>;
    revealPanelEls.forEach(panelEl => {
        new RevealPanel(panelEl.id);
    });
}

const blocksInit = () => {
    const alerts = Array.prototype.slice.call(document.querySelectorAll('[data-alert]')) as HTMLElement[];
    alerts.forEach(alert => {
        new Alert(alert);
    });

    const globalAlert = new GlobalAlert();

    // Accordion Init
    const accordions = nodeListToArray(document.querySelectorAll('[data-accordion-list]'));
    accordions.forEach(accordion => {
        const oneOpen = accordion.getAttribute('data-one-open-at-a-time');
        new Accordion({
            container: accordion,
            oneOpenAtATime: oneOpen === 'True'
        });
    });

    // Gallery Init
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery(gallery);
    });
}

init();
