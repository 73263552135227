export function stickyHeader() {
    window.addEventListener('load', function () {
        const header = document.getElementById('mheader');
        const nav = document.querySelector('[data-desktop-nav-container]') as HTMLElement;
        const main = document.getElementById('main');
        const stickyClass = 'mnav-sticky';
        let headerDimensions = header.offsetHeight - nav.offsetHeight;
        let lastScrollTop = 0;

        function adjustMainContentLocation() {
            main.style.paddingTop = header.offsetHeight.toString() + 'px';
        }

        // run on page load
        adjustMainContentLocation();

        function stickyDisplayHandler() {
            let currentPos = window.pageYOffset || document.documentElement.scrollTop;
            const body = document.querySelector('body') as HTMLElement;
            headerDimensions = header.offsetHeight - nav.offsetHeight;

            // handle header sticky
            if (currentPos === 0) {
                if (!body.classList.contains('locked')) {
                    header.classList.add(stickyClass);
                    header.classList.remove('sticky-top');
                }
            } else if (currentPos > lastScrollTop && currentPos > headerDimensions) {
                if (!body.classList.contains('locked')) {
                    // downscroll code
                    header.classList.add('sticky-top');
                    header.classList.remove(stickyClass);
                }
            } else if (currentPos < lastScrollTop && currentPos > headerDimensions) {
                if (!body.classList.contains('locked')) {
                    // upscroll code
                    header.classList.add(stickyClass);
                }
            }
            // set current position to lastScrollTop
            lastScrollTop = currentPos;
        }

        window.addEventListener('resize', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
                adjustMainContentLocation();
            })
        );

        window.addEventListener('scroll', () =>
            window.requestAnimationFrame(() => {
                stickyDisplayHandler();
            })
        );
    });
}