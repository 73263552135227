import { lockBody, unlockBody } from '../helpers/lockBody';
import { debounce, assign } from 'lodash';

// Static strings
const openMenuClasses = ['mnav-menu-is-open'];
const navLoadedClass = 'mheader-has-loaded';
const navId = "mheader";
const menuId = "nav-mobile-menu";

class MainNavigation {
    container: HTMLElement;
    menuTrigger: HTMLButtonElement;
    menuPanel: HTMLElement;
    menuFocusableChildren: Array<HTMLButtonElement | HTMLAnchorElement | HTMLInputElement>;
    menuIsOpen: boolean;
    navIsShowing: boolean;
    existingBodyStyles: string;
    lastScrollPosition: number;
    hasLoaded: boolean;
    currentNavHeight: number;
    bodyEl: HTMLBodyElement;
    onPageAnchorLinks: HTMLAnchorElement[];
    navSearchCloseBtn: null | HTMLElement;
    constructor(navigation: HTMLElement) {
        this.container = navigation;
        this.menuTrigger = this.container.querySelector(`[aria-controls="${menuId}"]`) as HTMLButtonElement;
        this.menuPanel = document.querySelector(`#${menuId}`) as HTMLElement;
        this.menuFocusableChildren = [].slice.call(this.menuPanel.querySelectorAll('button, a, input'));
        this.bodyEl = document.body as HTMLBodyElement;
        this.menuIsOpen = false;
        this.navIsShowing = true;
        this.hasLoaded = false;
        this.existingBodyStyles = '';
        this.navSearchCloseBtn = null;

        window.addEventListener(
            'resize',
            debounce(() => {
                this.closeMenuOnResize();
            }, 100)
        );
    }

    init = () => {

        this.menuTrigger.addEventListener('click', () => {
            if (this.menuIsOpen) {
                this.closeMenu();
            }
            else {
                this.openMenu();
            }
        });

        document.body.addEventListener('keyup', (e: KeyboardEvent) => {
            if (e.which == 27 && this.menuIsOpen) { // Escape key
                this.closeMenu();
                this.menuTrigger.focus();
            }
            if (e.which == 9) { // Tab key. Show nav if tabbed into, or close menu if tabbed out of.
                const focusInNav = this.menuPanel.contains(document.activeElement) || this.container.contains(document.activeElement);
                if (!focusInNav && this.menuIsOpen) {
                    this.closeMenu();
                }
            }
        });

        // Setup on page load
        this.closeMenu();
        this.setPageOffset();
    }

    openMenu = () => {
        this.hideSearchForm();
        this.menuPanel.setAttribute('aria-hidden', 'false');
        this.menuTrigger.setAttribute('aria-expanded', 'true');
        this.enableTabbableChildren();
        this.menuIsOpen = true;
        this.existingBodyStyles = lockBody();
        openMenuClasses.forEach(cssClass => {
            this.container.classList.add(cssClass);
            this.menuPanel.classList.add(cssClass);
        });
    }

    hideSearchForm() {
        this.navSearchCloseBtn = document.querySelector('[data-nav-search-close]') as HTMLElement;
        if (this.navSearchCloseBtn)
            this.navSearchCloseBtn.click();
    }

    closeMenu = () => {
        this.menuPanel.setAttribute('aria-hidden', 'true');
        this.menuTrigger.setAttribute('aria-expanded', 'false');
        this.disableTabbableChildren();
        this.menuIsOpen = false;
        unlockBody(this.existingBodyStyles);
        openMenuClasses.forEach(cssClass => {
            this.container.classList.remove(cssClass);
            this.menuPanel.classList.remove(cssClass);
        });
        this.setPageOffset();
    }

    enableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = 0;
        })
    }

    disableTabbableChildren = () => {
        this.menuFocusableChildren.forEach(child => {
            child.tabIndex = -1;
        })
    }

    setPageOffset = () => {
        if (!this.hasLoaded) {
            this.container.classList.add(navLoadedClass);
            this.hasLoaded = true;
        }
        this.currentNavHeight = this.container.offsetHeight;

    }

    resizeHandler = () => {
        this.setPageOffset();
    }

    closeMenuOnResize = () => {
        const mediaQuery = window.matchMedia('(min-width: 1024px)')
        if (mediaQuery.matches) {
            if (this.isTheMenuOpen) {
                this.closeMenu();
            }
        }
    }

    get isTheMenuOpen() {
        return this.menuIsOpen;
    }
}

let mainNavigation: MainNavigation = null;
const navContainer = document.querySelector(`#${navId}`) as HTMLElement;
if (navContainer) {
    mainNavigation = new MainNavigation(navContainer);
    (window as any).mainNavigation = mainNavigation;
}
export { mainNavigation };