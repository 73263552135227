import Splide from '@splidejs/splide';
import debounce = require('lodash.debounce');
import { nodeListToArray } from '../helpers/nodeListToArray';

export class HomeCarousels {
    homeCarouselContainers: HTMLElement;
    triggers: HTMLElement[];
    constructor(homeCarouselContainers: HTMLElement) {
        const textSplide = homeCarouselContainers.querySelector('[data-homecarousel-text-splide]') as HTMLElement;
        const imageSplide = homeCarouselContainers.querySelector('[data-homecarousel-image-splide]') as HTMLElement;
        const contentSplide = homeCarouselContainers.querySelector('[data-homecarousel-content-splide]') as HTMLElement;
        var textSlider = new Splide(textSplide, {
            autoWidth: true,
            gap: '1.25rem',
            accessibility: true,
            perPage: 1,
            perMove: 1,
            // @ts-ignore
            pagination: false,
            // @ts-ignore
            slideFocus: false,
            arrows: true,
            loop: true,
            drag: true,
            padding: {
                right: '5rem',
                left: 'calc((100vw - 78rem) / 2)',
            },

            breakpoints: {
                1024: {
                    padding: {
                        right: '0',
                        left: 'calc((100vw - 78rem) / 2)',
                    },
                },
            }
        }).mount();

        var imageSlider = new Splide(imageSplide, {
            type: 'loop',
            pagination: false,
            arrows: false,
            autoplay: false,
            drag: false,
        });

        var contentSlider = new Splide(contentSplide, {
            type: 'loop',
            pagination: false,
            arrows: false,
            autoplay: false,
            drag: false,
        });

        imageSlider.sync(textSlider).mount();
        contentSlider.sync(textSlider).mount();
        this.updateActiveOnClick(textSlider);

    }

    updateSlideIndex(slider) {
        const currentIndex = slider.index;
        slider.go(currentIndex)
    }

    updateActiveOnClick(slider) {
        slider.Components.Elements.slides.forEach((slide, index) => {
            slide.addEventListener("click", () => {
                slider.go(index);
            })
        })
    }
}
